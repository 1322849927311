<script setup lang="ts">
const props = defineProps<{
	row?: boolean
}>();
</script>

<template>
	<div class="dialog__footer">
		<div
			class="dialog__actions"
			:class="[{
				'dialog__actions--row': props.row,
			}]"
		>
			<slot />
		</div>
		<div class="dialog__actions-end" v-if="$slots.end">
			<slot name="end" />
		</div>
	</div>
</template>
