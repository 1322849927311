<script setup lang="ts">
const props = defineProps<{
	areaLabel?: string
	overflow?: boolean
}>();
</script>

<template>
	<div class="dialog__body" :class="{ 'dialog__body--overflow': props.overflow }">

		<slot />

		<div class="dialog__area" v-if="$slots.area || props.areaLabel">
			<div class="dialog__area-header">
				<p class="dialog__area-label" v-if="props.areaLabel">
					{{ props.areaLabel }}
				</p>
				<div class="dialog__area-actions" v-if="$slots.areaActions">
					<slot name="areaActions" />
				</div>
			</div>
			<div class="dialog__area-main">
				<slot name="area" />
			</div>
		</div>
	</div>
</template>
