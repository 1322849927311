<script setup lang="ts">
interface Props {
	label: string
	icon: string
	close?: boolean
}

const props = withDefaults(defineProps<Props>(), {
	close: true,
});

const emit = defineEmits<{
	(event: 'update:visible', value: boolean): void
}>();
</script>

<template>
	<div class="dialog__header">
		<div class="dialog__icon" v-if="props.icon">
			<Icon :name="props.icon" />
		</div>
		<h2 class="dialog__title" v-if="props.label">
			<span>{{ props.label }}</span>
		</h2>
		<button
			type="button"
			class="dialog__close"
			v-if="props.close"
			@click="emit('update:visible', false)"
		>
			<Icon name="cancel" :size="20" />
			<span class="aria:sr">{{ $t('props.closeDialog') }}</span>
		</button>
	</div>
</template>
